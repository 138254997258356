import React, { useState } from 'react';
import { Card, Form, Button, Container, Row, Col } from 'react-bootstrap';
import credentials from '../config/credentials.json';
import logoImage from '../assets/images/logo/project_o.png';

import '../assets/css/Login.css';

const Login = ({ setAuth }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      username === credentials.username &&
      password === credentials.password
    ) {
      setAuth(true);
      localStorage.setItem('isLoggedIn', true);
    } else {
      alert('Invalid credentials');
    }
  };

  return (
    <div className='login-background background-grey'>
      <Container>
        <Row className='justify-content-center align-items-center min-vh-100'>
          <Col sm={6} md={4}>
            <Card className='background-grey card-over mtop'>
              <Card.Body>
                <Card.Title className='text-center mb-4'>
                  <img className='login-logo' src={logoImage} alt='logo' />{' '}
                </Card.Title>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className='mb-2 background-white'>
                    <Form.Control
                      type='text'
                      placeholder='Username'
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className='mb-4 background-white'>
                    <Form.Control
                      type='password'
                      placeholder='Password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                  <div className='button-div'>
                    <Button
                      className='login-button button-div'
                      variant='primary'
                      type='submit'
                      block
                    >
                      Log in
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
