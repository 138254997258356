import Home from './Home';
import Boardgame from './Boardgame';
import Login from './Login';

const routes = [
  { path: '/', component: Home },
  { path: '/boardgame', component: Boardgame },
  { path: '/login', component: Login },
];

export default routes;
