import React from 'react';
import Header from '../components/header/Header';
import SideBar from '../components/layouts/boardgame/SideBar';
import ItemContent from '../components/layouts/boardgame/ItemContent';

const Boardgame = () => {
  return (
    <div className='home-8'>
      <Header />
      <section className='tf-item tf-section'>
        <div className='themesflat-container'>
          <div className='row'>
            <div className='col-box-17'>
              <SideBar />
            </div>

            <div className='col-box-83'>
              <ItemContent />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Boardgame;
