const menus = [
  {
    id: 1,
    name: 'Home',
    links: '/',
  },
  {
    id: 2,
    name: 'Boardgame DB',
    links: '/boardgame',
  },
];

export default menus;
