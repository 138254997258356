import { useState, useEffect } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import routes from './pages/index';

import Home from './pages/Home';
import Login from './pages/Login';

function App() {
  const [isAuthenticated, setAuth] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('isLoggedIn')) {
      setAuth(true);
    }
  }, []);

  return isAuthenticated ? (
    <Routes>
      {routes.map((data) => (
        <Route
          exact={true}
          path={data.path}
          element={<data.component />}
          key={data.path}
        />
      ))}
      <Route path='*' element={<Home />} /> {/* Catch-all route */}
    </Routes>
  ) : (
    <Login setAuth={setAuth} />
  );
}

export default App;
